import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutModes } from '@grafana/data';
import { WorkflowsState } from 'app/types';

export const initialWorkflowsState: WorkflowsState = {
  workflows: [],
  searchQuery: '',
  searchPage: 1,
  hasFetched: false,
  layoutMode: LayoutModes.List,
};

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: initialWorkflowsState,
  reducers: {
    workflowsLoaded: (state, action): WorkflowsState => {
      // console.log("test check : ",action.payload);

      return { ...state, hasFetched: true, workflows: action.payload };
    },
    setSearchQuery: (state, action): WorkflowsState => {
      // console.log("test check : ",action.payload);
      return { ...state, searchQuery: action.payload };
    },
    setWorkflowsLayoutMode: (state, action): WorkflowsState => {
      // console.log("test check : ",action.payload);
      return { ...state, layoutMode: action.payload };
    },
    setWorkflowsSearchPage: (state, action: PayloadAction<number>): WorkflowsState => {
      // console.log("test check : ",action.payload);
      return { ...state, searchPage: action.payload };
    },
  },
});

export const { workflowsLoaded, setSearchQuery, setWorkflowsSearchPage, setWorkflowsLayoutMode } =
  workflowsSlice.actions;

export const workflowsReducer = workflowsSlice.reducer;

export default {
  workflows: workflowsReducer,
};
