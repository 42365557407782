/* eslint-disable no-duplicate-imports */
import { getBackendSrv } from '@grafana/runtime';
import { ThunkResult } from 'app/types';
import { SecurityIncidentListCreate, SecurityIncidentListUpdate , SecurityIncident} from 'app/types';

import { resetStateComment } from '../../comment/state/reducers';
import { knowledgesLoaded } from '../../knowlage-management/state/reducers';

import { securityIncidentsLoadbyid, securityIncidentsLoaded, usersLoadbyOrgId , userRole, feedbackLoopData, resetState} from './reducers';
// import { securityIncidentsLoaded } from './reducers';

// const API_BASE_URL = '/security_incident';

export function fetchSecurityIncidents(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      let response = await getBackendSrv().get(`/api/security_incident/byorgid`);
      // console.log("check fetchSecurityIncidents() in actions:", response);

      if (response.message === "null") {
        response = [];
      }
      // console.log("check fetchSecurityIncidents 123: " , response);
      // dispatch(resetState());
      dispatch(resetStateComment());
      dispatch(securityIncidentsLoaded(response));
    } catch (error) {
      console.error('Failed to fetch security incidents', error);
    }
  };
}

export function resetStateSecurityIncidents(): ThunkResult<void> {
  return async (dispatch) => {
    dispatch(resetState());
    dispatch(resetStateComment());
  };
}

export function createSecurityIncident(incident: SecurityIncidentListCreate): ThunkResult<void> {
  // console.log("check createSecurityIncident() in actions",incident);
  return async (dispatch) => {
      // console.log("check createSecurityIncident() in actions",incident);
      if (incident.files && incident.files.length > 0) {
        incident.files = incident.files.map(file => {
          if (file.CreatedAt) {
            return {
              ...file,
              CreatedAt: formatDateToISO(file.CreatedAt)
            };
          }
          return file;
        });
      }
      await getBackendSrv().post(`/api/security_incident`, incident);
      // fetchSecurityIncidents();
      // console.log("check createSecurityIncident() in actions",response);
      // Handle successful creation, maybe dispatch an action indicating success
  };
}
export function updateSecurityIncident(incident: SecurityIncidentListUpdate | SecurityIncident): ThunkResult<void> {let setUrl = ``;
  return async (dispatch) => {
    try {
      // console.log("Check incident in updateSecurityIncident : ",incident);
      if (isSecurityIncidentListUpdate(incident)) {
        setUrl = `/api/security_incident`;
        // Only format dates if the incident is of type SecurityIncidentListUpdate
        incident.occurred = formatDateToISO(incident.occurred);
        incident.reminder = formatDateToISO(incident.reminder);
        if (incident.files && incident.files.length > 0) {
          incident.files = incident.files.map(file => {
            if (file.CreatedAt) {
              return {
                ...file,
                CreatedAt: formatDateToISO(file.CreatedAt)
              };
            }
            return file;
          });
        }
      } else {
        setUrl = `/api/security_incident/phase`;
        // If the incident is of type SecurityIncident
        // console.log("Check incident",incident);
        let updatedIncident: SecurityIncident = { ...incident }; // Create a shallow copy
        if (incident.phase === 'Registered') {
          updatedIncident = { ...updatedIncident, phase: 'In-Progress' };
        } else if (incident.phase === 'In-Progress') {
          updatedIncident = { ...updatedIncident, phase: 'Close' };
        }
        // Assign the updated incident to the original variable
        incident = updatedIncident;
      }

      // const incidentJson = JSON.stringify(incident);

      // console.log("updateSecurityIncident request:", incidentJson);
      await getBackendSrv().put(setUrl, incident);
      // return { status: 200, body: { message: "Security incident updated successfully" } };
      // console.log("updateSecurityIncident response:", response);
      // Dispatch an action if needed to update the store
    } catch (error) {
      console.error('Failed to update security incident:', error);
    }
  };
}

function isSecurityIncidentListUpdate(incident: any): incident is SecurityIncidentListUpdate {
  return 'name' in incident && 'owner' in incident && 'assign_to' in incident &&
         'occurred' in incident && 'reminder' in incident && 'role' in incident &&
         'type' in incident && 'severity' in incident && 'playbook' in incident &&
         'phase' in incident && 'detail' in incident && 'created_at' in incident &&
         'updated_at' in incident && 'files' in incident;
}

// export function deleteSecurityIncident(id: SecurityIncidentListDelete): ThunkResult<void> {
//   return async (dispatch) => {
//     try {
//       const response = await getBackendSrv().delete(`/security_incident/${id.id}`);
//       console.log("Security incident deleted", response);
//       // Redirect to the security incidents page
//       // window.location.href = '/security_incident';
//       // dispatch(response);
//     } catch (error) {
//       console.error('Failed to delete security incident', error);
//     }
//   };
// }

export function fetchSecurityIncidentById(id: number): ThunkResult<void> {
  return async (dispatch) => {
    // console.log("check fetchSecurityIncidentById() in actions",id);
    const response = await getBackendSrv().get(`/api/security_incident/${id}`);
    // console.log("check response",response);
    response.occurred = convertDateFormat(response.occurred);
    response.reminder = convertDateFormat(response.reminder);
    // if (response.files.length > 0) {
    //   console.log("check response",response.files);
    //   response.files.forEach((file: { CreatedAt: string; }) => {
    //     file.CreatedAt = convertDateFormat(file.CreatedAt);
    //   });
    // }
    // console.log("check response",response.files);
    // console.log("check response",response);
    dispatch(securityIncidentsLoadbyid(response));
    // return response
    // dispatch((response));
  };
}

function convertDateFormat(inputDate: string): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
//  return "";
}

// function convertDateFormatSlash(inputDate: string): string {
//   const date = new Date(inputDate);
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, '0');
//   const day = date.getDate().toString().padStart(2, '0');
//   return `${year}/${month}/${day}`;
// //  return "";
// }

function formatDateToISO(dateString: string): string {
  // Parse the date string into a Date object
  const date = new Date(dateString);
  // console.log("Check date:" , date);
  // Format the date in ISO format
  const isoDate = date.toISOString();
  // console.log("Check isoDate:" , isoDate);
  return isoDate;
}

export function fetchUsers(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      let response = await getBackendSrv().get(`/user_by_orgid`);
      // console.log("check fetchUsers() in actions:", response);

      if (response.message === "null") {
        response = [];
        // console.log("check fetchUsers() in actions response.message === null:", response);
      }
      // console.log("check fetchUsers() in actions response.message === null:", response);
      
      dispatch(usersLoadbyOrgId(response));
    } catch (error) {
      console.error('Failed to fetch security incidents', error);
    }
  };
}

export function fetchGetRole(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      let response = await getBackendSrv().get(`/api/security_incident/get_role`);
      // console.log("check fetchSecurityIncidents() in actions:", response);
      dispatch(userRole(response));
    } catch (error) {
      console.error('Failed to fetch security incidents', error);
    }
  };
}

export function fetchGetFeedbackLoopData(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      let response = await getBackendSrv().get(`/api/security_incident/getfeedbackloop`);
      // console.log("check fetchGetFeedbackLoopData() in actions:", response);
      // console.log('Check response:',response);
      if(response === null) {
        response = [];
      }
        
      dispatch(feedbackLoopData(response));
    } catch (error) {
      console.error('Failed to fetch security incidents', error);
    }
  };
}

export function fetchKnowledge(incident: SecurityIncidentListCreate | SecurityIncidentListUpdate): ThunkResult<void> {
  // console.log('Test Check fetchKnowledge incident:', incident);
  return async (dispatch) => {
    try {
      let check: SecurityIncident;
      check = { 
        name: incident.name,
        owner: 0,
        assign_to: 0,
        occurred: new Date(),
        reminder: new Date(),
        role: '',
        type: incident.type,
        severity: '',
        playbook: 0,
        phase: '',
        detail: incident.detail,
        created_at: new Date(),
        updated_at: new Date()
      };
      // console.log('Test Check fetchKnowledge incident 2:', incident);
      let response = await getBackendSrv().post(`/api/knowledge_base/incident`, check);
      // console.log("check fetchGetFeedbackLoopData() response in actions:", response);
      // console.log('Check response:',response);
      if(response === null) {
        response = [];
      }
        
      dispatch(knowledgesLoaded(response));
    } catch (error) {
      console.error('Failed to fetch security incidents', error);
    }
  };
}
