import { SecurityIncidentsState, SetStoreState, StoreState } from 'app/types';

export const getSecurityIncidentsCount = (state: SecurityIncidentsState) => state.incidents.length;

export const getSearchQuery = (state: SecurityIncidentsState) => state.searchQuery;
export const getSecurityIncidents = (state: StoreState) => {
  const { incidents = [] } = state.securityIncidents;
  const regex = new RegExp(state.securityIncidents.searchQuery, 'i');
  return Array.isArray(incidents)
    ? incidents.filter((incident) => {
        const ownerUser = state.securityIncidents.userLists.find((user) => user.id === incident.owner);
        return (
          regex.test(incident.name) ||
          (ownerUser && regex.test(ownerUser.login)) ||
          regex.test(incident.type) ||
          regex.test(incident.severity) ||
          regex.test(incident.phase) ||
          (incident.created_at && regex.test(convertDateFormatSlash(incident.created_at)))
        );
      })
    : [];
};

export const getSecurityIncidentsById = (state: SecurityIncidentsState) => {
  // const test = state.newIncidentList.occurred.toISOString().substr(0, 10);
  // console.log("check test :" , test)
  // state.newIncidentList.occurred = test;
  // let res: Date = new Date(test);
  // state.newIncidentList.occurred = res
  // console.log('check state.newIncidentList :', state.newIncidentListUpdate);
  return state.newIncidentListUpdate;
};

export const getSecurityIncidentsCreate = (state: StoreState) => {
  return state.securityIncidents.newIncidentListUpdate;
};

export const getUsersByOrgId = (state: SecurityIncidentsState) => {
  // const regex = RegExp(state.searchQuery, 'i');
  // return state.userLists.filter((user) => {
  //   return regex.test(user.login);
  // });
  return Array.isArray(state.userLists) ? state.userLists : [];
};

export const getUsersAdminByOrgId = (state: SecurityIncidentsState) => {
  const regex = RegExp(state.searchQuery, 'i');
  return Array.isArray(state.userLists)
    ? state.userLists.filter((user) => {
        return regex.test(user.login) && user.role === 'Admin';
      })
    : [];
  // state.userLists.filter((user) => {
  // return regex.test(user.login) && user.role === 'Admin';
};

// export const filterKnowledgeBase = (state: StoreState) => {
//   if (state.securityIncidents.searchQuery === "") {
//     const regex = RegExp(" ", 'i');
//     return state.knowledges.knowledges.filter((knowledges) => {
//       return regex.test(knowledges.topic); ;
//     });
//   }else{
//     const regex = RegExp(state.securityIncidents.searchQuery, 'i');
//     return state.knowledges.knowledges.filter((knowledges) => {
//       return regex.test(knowledges.topic); ;
//     });
//   }
// };

export const filterKnowledgeBase = (state: StoreState) => {
  // const regex = RegExp(state.securityIncidents.searchQuery || 'null', 'i');
  // console.log("Check state.knowledges:",state.knowledges.knowledges);
  // return state.knowledges.knowledges.filter(knowledges => regex.test(knowledges.topic));
  return Array.isArray(state.knowledges.knowledges) ? state.knowledges.knowledges : [];
};

export const getFeedbackLoopData = (state: SecurityIncidentsState) => {
  return Array.isArray(state.feedbackLoopData) ? state.feedbackLoopData : [];
};

export const getRole = (state: SecurityIncidentsState) => {
  // console.log('User Role:', state.userRole);
  return state.userRole;
};

// export const getRoleUser = (state: StoreState) => {
//   // console.log('User Role:', state.securityIncidents.userRole);
//   return state.securityIncidents.userRole
// };

export const getSecurityIncidentsSearchQuery = (state: SecurityIncidentsState) => state.searchQuery;
export const getSecurityIncidentsLayoutMode = (state: SecurityIncidentsState) => state.layoutMode;
export const getSecurityIncidentsSearchPage = (state: SecurityIncidentsState) => state.searchPage;

export const getNavModel = (state: SetStoreState, id: string) => {
  return state.navIndex[id];
};

// export const getSecurityIncidents = (state: StoreState) => {
//   return state.securityIncidents.incidents;
// };

export const getSecurityIncidentsTimeFilter = (state: SecurityIncidentsState) => state.timeFilter;
export const getSecurityIncidentsDashboardNumData = (state: SecurityIncidentsState) => state.numData;
export const getSecurityIncidentsDashboardTableData = (state: SecurityIncidentsState) => state.tableData;
export const getSecurityIncidentsDashboardChartData = (state: SecurityIncidentsState) => state.chartData;
export const getUserLoginByOwner = (state: SecurityIncidentsState, owner: number) => {
  const matchedUser = state.userLists.find((user) => user.id === owner);

  if (matchedUser) {
    return matchedUser.login;
  }

  return null; // or any default value if the user is not found
};

const convertDateFormatSlash = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const hoursStr = hours.toString().padStart(2, '0');

  return `${month}/${day}/${year}, ${hoursStr}:${minutes}:${seconds} ${ampm}`;
};
